@import 'vars';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--base-background);
  color: var(--base-color);
  font-size: 16px;
}

body {
  font: var(--base-font);
  min-height: 100vh;
}
